import React from 'react';
import './global.css';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful 
 * when you want to change your logo depending on the theme you are on. 
 */
export default function Logo({ fill }) {
  return (
    

<svg width="60" height="60" xmlns="http://www.w3.org/2000/svg">

 <text fill={fill} xmlSpace="preserve" textAnchor="start" fontFamily="sans-serif" fontSize="43" id="svg_2" y="45" x="1" opacity="undefined" fillOpacity="null" strokeOpacity="null" strokeDasharray="null" strokeWidth="0" stroke="#000">FD</text>

</svg>
  );
}